import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { Box, Button, Field, Heading, Card } from '@hackclub/design-system'
import Swal from 'sweetalert2'

import styled from 'styled-components'

const Form = Box.withComponent('form')

const ProgramBrochure = props => {
  const [open, setOpen] = useState(false)
  const [user, setUser] = useState({
    name: '',
    email: '',
    phone: ''
  })

  const onOpenModal = () => {
    setOpen(true)
  }

  const onCloseModal = () => {
    setOpen(false)
  }

  const onChange = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }

  const validate = () => {
    const errors = {}
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const nameRegex = /[a-zA-Z]+(?:(?:\. |[' ])[a-zA-Z]+)*/
    if (user.name) {
      if (!nameRegex.test(user.name)) {
        errors.name = 'Please enter Valid  Name'
      }
    } else {
      errors.name = ''
    }
    if (user.email) {
      if (!emailRegex.test(user.email)) {
        errors.email = ' Please enter Valid Email'
      }
    } else {
      errors.email = ''
    }
    if (user.phone) {
      if (user.phone.length < 4) {
        errors.phone = ' Please enter Valid Phone Number'
      }
    } else {
      errors.phone = ''
    }
    return {
      errors,
      isValid: Object.keys(errors).length === 0
    }
  }

  const onSubmit = () => {
    if (user.email === '' || user.name === '' || user.phone === '') {
      Swal.fire('Please Enter All Fields')
    } else {
      fetch('/.netlify/functions/email', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          from: 'noreply@codingsastra.com',
          to: user.email,
          subject: 'Brochure From Coding Sastra',
          message: `Coding Sastra`,
          html: `<html>
          <head>
            <meta name="viewport" content="width=device-width" />
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <title>Contact Us- Coding Sastra</title>
            <meta name="description" content="Coding Sastra is one of the best software training institutes in Hyderabad. With branches in Kukatpally and Visakhapatnam. We provide training like Mobile App, Web development, data science courses" />

            <style>
              img {
                border: none;
                -ms-interpolation-mode: bicubic;
                max-width: 100%; 
              }
              body {
                background-color: #f6f6f6;
                font-family: sans-serif;
                -webkit-font-smoothing: antialiased;
                font-size: 14px;
                line-height: 1.4;
                margin: 0;
                padding: 0;
                -ms-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%; 
              }
              table {
                border-collapse: separate;
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                width: 100%; }
                table td {
                  font-family: sans-serif;
                  font-size: 14px;
                  vertical-align: top; 
              }
            
              .body {
                background-color: #f6f6f6;
                width: 100%; 
              }
              /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
              .container {
                display: block;
                margin: 0 auto !important;
                /* makes it centered */
                max-width: 580px;
                padding: 10px;
                width: 580px; 
              }
              /* This should also be a block element, so that it will fill 100% of the .container */
              .content {
                box-sizing: border-box;
                display: block;
                margin: 0 auto;
                max-width: 580px;
                padding: 10px; 
              }
              
              .main {
                background: #ffffff;
                border-radius: 3px;
                width: 100%; 
              }
              .wrapper {
                box-sizing: border-box;
                padding: 20px; 
              }
              .content-block {
                padding-bottom: 10px;
                padding-top: 10px;
              }
              .footer {
                clear: both;
                margin-top: 10px;
                text-align: center;
                width: 100%; 
              }
                .footer td,
                .footer p,
                .footer span,
                .footer a {
                  color: #999999;
                  font-size: 12px;
                  text-align: center; 
              }
      
              h1,
              h2,
              h3,
              h4 {
                color: #000000;
                font-family: sans-serif;
                font-weight: 400;
                line-height: 1.4;
                margin: 0;
                margin-bottom: 30px; 
              }
              h1 {
                font-size: 35px;
                font-weight: 300;
                text-align: center;
                text-transform: capitalize; 
              }
              p,
              ul,
              ol {
                font-family: sans-serif;
                font-size: 14px;
                font-weight: normal;
                margin: 0;
                margin-bottom: 15px; 
              }
                p li,
                ul li,
                ol li {
                  list-style-position: inside;
                  margin-left: 5px; 
              }
              a {
                color: #3498db;
                text-decoration: underline; 
              }
              .btn {
                box-sizing: border-box;
                width: 100%; }
                .btn > tbody > tr > td {
                  padding-bottom: 15px; }
                .btn table {
                  width: auto; 
              }
                .btn table td {
                  background-color: #ffffff;
                  border-radius: 5px;
                  text-align: center; 
              }
                .btn a {
                  background-color: #ffffff;
                  border: solid 1px #3498db;
                  border-radius: 5px;
                  box-sizing: border-box;
                  color: #3498db;
                  cursor: pointer;
                  display: inline-block;
                  font-size: 14px;
                  font-weight: bold;
                  margin: 0;
                  padding: 12px 25px;
                  text-decoration: none;
              }
              .btn-primary table td {
                background-color: #3498db; 
              }
              .btn-primary a {
                background-color: #3498db;
                border-color: #3498db;
                color: #ffffff; 
              }
              .last {
                margin-bottom: 0; 
              }
              .first {
                margin-top: 0; 
              }
              .align-center {
                text-align: center; 
              }
              .align-right {
                text-align: right; 
              }
              .align-left {
                text-align: left; 
              }
              .clear {
                clear: both; 
              }
              .mt0 {
                margin-top: 0; 
              }
              .mb0 {
                margin-bottom: 0; 
              }
              .preheader {
                color: transparent;
                display: none;
                height: 0;
                max-height: 0;
                max-width: 0;
                opacity: 0;
                overflow: hidden;
                mso-hide: all;
                visibility: hidden;
                width: 0; 
              }
              .powered-by a {
                text-decoration: none; 
              }
              hr {
                border: 0;
                border-bottom: 1px solid #f6f6f6;
                margin: 20px 0; 
              }
              @media only screen and (max-width: 620px) {
                table[class=body] h1 {
                  font-size: 28px !important;
                  margin-bottom: 10px !important; 
                }
                table[class=body] p,
                table[class=body] ul,
                table[class=body] ol,
                table[class=body] td,
                table[class=body] span,
                table[class=body] a {
                  font-size: 16px !important; 
                }
                table[class=body] .wrapper,
                table[class=body] .article {
                  padding: 10px !important; 
                }
                table[class=body] .content {
                  padding: 0 !important; 
                }
                table[class=body] .container {
                  padding: 0 !important;
                  width: 100% !important; 
                }
                table[class=body] .main {
                  border-left-width: 0 !important;
                  border-radius: 0 !important;
                  border-right-width: 0 !important; 
                }
                table[class=body] .btn table {
                  width: 100% !important; 
                }
                table[class=body] .btn a {
                  width: 100% !important; 
                }
                table[class=body] .img-responsive {
                  height: auto !important;
                  max-width: 100% !important;
                  width: auto !important; 
                }
              }
              .name{
                text-transform:capitalize;
              }
              @media all {
                .ExternalClass {
                  width: 100%; 
                }
                .ExternalClass,
                .ExternalClass p,
                .ExternalClass span,
                .ExternalClass font,
                .ExternalClass td,
                .ExternalClass div {
                  line-height: 100%; 
                }
                .apple-link a {
                  color: inherit !important;
                  font-family: inherit !important;
                  font-size: inherit !important;
                  font-weight: inherit !important;
                  line-height: inherit !important;
                  text-decoration: none !important; 
                }
                #MessageViewBody a {
                  color: inherit;
                  text-decoration: none;
                  font-size: inherit;
                  font-family: inherit;
                  font-weight: inherit;
                  line-height: inherit;
                }
                .btn-primary table td:hover {
                  background-color: #34495e !important; 
                }
                .btn-primary a:hover {
                  background-color: #34495e !important;
                  border-color: #34495e !important; 
                } 
              }
            </style>
          </head>
          <body class="">
            <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
              <tr>
                <td>&nbsp;</td>
                <td class="container">
                  <div class="content">    
                    <table role="presentation" class="main">
                      <tr>
                        <td class="wrapper">
                          <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                            <tr>
                              <td>
                                <p class="name">Hello ${user.name},</p>
                                 <p>Thank you for your interest in our Technologies. Please find the attached brochure for your reference.</p>
                                <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary">
                                  <tbody>
                                    <tr>
                                      <td align="left">
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                          <tbody>
                                            <tr>
                                              <td> <a href="http:${props.url}" target="_blank">Click here to dowload Brochure</a> </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p>Thanks for downloading , Good luck! Happy reading!.</p>
                                <p>We will contact you soon <br/>Email: ${user.email}<br/>Phone: ${user.phone} </p>
                                <p><b>Regards,<b>
                                <br/>Team Coding Sastra</p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <div class="footer">
                      <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td class="content-block">
                            <span class="apple-link">HYDERABAD MIG 27/1, Road #6, Dharma Reddy Colony, Phase 1 Behind VRK Silks,
                                KPHB Colony Hyderabad TS-500072.</span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </td>
                <td>&nbsp;</td>
              </tr>
            </table>
          </body>
        </html>`
        })
      })
        .then(response => response.json())
        .then(
          Swal.fire({
            icon: 'success',
            title: 'Program Brouchure has been sent successfully to your email',
            showConfirmButton: false,
            timer: 3000
          })
        )
        .then(onClear())
        .then(onCloseModal())
        .catch(error => {
          console.error(error)
          Swal.fire(
            'Ooops..',
            `Failed to send email. ${JSON.stringify(error)}`,
            'error'
          )
        })
    }
  }

  const onClear = () => {
    setUser({
      name: '',
      email: '',
      phone: ''
    })
  }

  const { errors } = validate()

  const bg = {
    modal: {
      borderRadius: '9px'
    }
  }

  const NOTE = styled.div`
    width: 35vw;
    @media (max-width: 2561px) {
      width: 30vw;
    }
    @media (max-width: 1025px) {
      width: 44vw;
    }
    @media (max-width: 426px) {
      width: 80vw;
    }
  `

  return (
    <>
      <div>
        <Button onClick={onOpenModal}>Download</Button>
        <Modal
          open={open}
          onClose={onCloseModal}
          center
          closeOnOverlayClick={false}
          styles={bg}
        >
          <Box align="center">
            <NOTE>
              <Card color="black">
                <Heading.h2 pt={4} fontSize={3}>
                  Send Brochure
                </Heading.h2>
              </Card>
            </NOTE>
            <Box mt={4}>
              <Form pl={2}>
                <Field
                  label="Name"
                  name="name"
                  placeholder="Enter Name"
                  mb={2}
                  value={user.name}
                  onChange={onChange}
                  error={errors.name}
                />
                <Field
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Enter Email"
                  mb={2}
                  value={user.email}
                  onChange={onChange}
                  error={errors.email}
                />
                <Field
                  label="Phone"
                  name="phone"
                  type="tel"
                  placeholder="Enter Phone Number"
                  mb={2}
                  value={user.phone}
                  onChange={onChange}
                  error={errors.phone}
                />
                <Button onClick={onSubmit} width={150}>
                  Send
                </Button>
              </Form>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  )
}

export default ProgramBrochure
