import React, { useState } from 'react'
import { Box, Button, Field } from '@hackclub/design-system'
import PayTMlogo from '../../../../static/pos.svg'

const Form = Box.withComponent('form')

const PayTM = props => {
  const [paymentSubmitted, setPaymentSubmitted] = useState(false)
  const [checksumRequest, setChecksumRequest] = useState(false)
  const [paymentForm, setPaymentForm] = useState({
    ORDER_ID: props.orderId,
    CUST_ID: props.customerId,
    INDUSTRY_TYPE_ID: 'Retail',
    CHANNEL_ID: 'WEB',
    TXN_AMOUNT: props.amount,
    MID: '',
    WEBSITE: '',
    PAYTM_MERCHANT_KEY: '',
    CALLBACK_URL: '',
    PAYTM_FINAL_URL: '',
    CHECKSUMHASH: '',
    MOBILE_NO: props.mobileNo,
    EMAIL: props.email
  })

  const getConfig = () => {
    fetch('/api/paytm/config')
      .then(resp => resp.json())
      .then(config => {
        let paymentForm = Object.assign({}, paymentForm)
        paymentForm['MID'] = config.MID
        paymentForm['PAYTM_MERCHANT_KEY'] = config.PAYTM_MERCHANT_KEY
        paymentForm['PAYTM_FINAL_URL'] = config.PAYTM_FINAL_URL
        paymentForm['WEBSITE'] = config.WEBSITE
        setPaymentForm({ paymentForm })
      })
      .catch(err => {
        alert(
          'Failed to get PayTM configuration. Please contact website administrator.'
        )
      })
  }

  const onChange = e => {
    setPaymentForm({
      ...paymentForm,
      [e.target.name]: e.target.value
    })
  }

  const onChecksumRequest = e => {
    getChecksum().then(checksum => {
      let paymentForm = Object.assign({}, paymentForm)
      paymentForm['CHECKSUMHASH'] = checksum.CHECKSUMHASH
      paymentForm['CALLBACK_URL'] = checksum.CALLBACK_URL
      setPaymentForm({ paymentForm })
    })
  }

  const getChecksum = () => {
    return new Promise((resolve, reject) => {
      var formBody = []
      for (var property in paymentForm) {
        var encodedKey = encodeURIComponent(property)
        var encodedValue = encodeURIComponent(paymentForm[property])
        if (property !== 'CHECKSUMHASH' && property !== 'PAYTM_FINAL_URL') {
          formBody.push(encodedKey + '=' + encodedValue)
        }
      }

      formBody = formBody.join('&')

      fetch('/api/paytm/checksum', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody
      })
        .then(resp => resp.json())
        .then(checksum => {
          resolve(checksum)
        })
        .catch(err => {
          console.log(JSON.stringify(err))
          reject(err)
        })
    })
  }

  return (
    <>
      <h5>Pay with PayTM</h5>
      <div>
        <Form action={paymentForm.PAYTM_FINAL_URL}>
          <Field
            id="ORDER_ID"
            type="hidden"
            value={paymentForm.ORDER_ID}
            onChange={onChange}
            name="ORDER_ID"
          />
          <Field
            id="CUST_ID"
            type="hidden"
            name="CUST_ID"
            value={paymentForm.CUST_ID}
            onChange={onChange}
          />
          <Field
            id="INDUSTRY_TYPE_ID"
            type="hidden"
            name="INDUSTRY_TYPE_ID"
            value={paymentForm.INDUSTRY_TYPE_ID}
            onChange={onChange}
          />
          <Field
            id="CHANNEL_ID"
            type="hidden"
            name="CHANNEL_ID"
            value={paymentForm.CHANNEL_ID}
            onChange={onChange}
          />
          <Field
            id="TXN_AMOUNT"
            type="hidden"
            name="TXN_AMOUNT"
            value={paymentForm.TXN_AMOUNT}
            onChange={onChange}
            readOnly
          />
          <Field
            id="MID"
            type="hidden"
            name="MID"
            value={paymentForm.MID}
            onChange={onChange}
            readOnly
          />
          <Field
            id="MOBILE_NO"
            type="hidden"
            name="MOBILE_NO"
            value={paymentForm.MOBILE_NO}
            onChange={onChange}
            readOnly
          />
          <Field
            id="EMAIL"
            type="hidden"
            name="EMAIL"
            value={paymentForm.EMAIL}
            onChange={onChange}
            readOnly
          />
          <Field
            id="WEBSITE"
            type="hidden"
            name="WEBSITE"
            value={paymentForm.WEBSITE}
            onChange={onChange}
            readOnly
          />
          <Field
            id="PAYTM_MERCHANT_KEY"
            type="hidden"
            name="PAYTM_MERCHANT_KEY"
            value={paymentForm.PAYTM_MERCHANT_KEY}
            onChange={onChange}
            readOnly
          />
          <Field
            id="CALLBACK_URL"
            type="hidden"
            name="CALLBACK_URL"
            value={paymentForm.CALLBACK_URL}
            onChange={onChange}
            readOnly
          />
          <Field
            id="CHECKSUMHASH"
            type="hidden"
            name="CHECKSUMHASH"
            value={paymentForm.CHECKSUMHASH}
            readOnly
          />
          <Box align="center">
            <Button onClick={onChecksumRequest}>
              <img
                src={PayTMlogo}
                width="150px"
                height="40px"
                alt="pay with paytm"
              />
            </Button>
          </Box>
        </Form>
      </div>
    </>
  )
}

export default PayTM
