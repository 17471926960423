import React from 'react'
import { PayPalButton } from 'react-paypal-button-v2'
import Swal from 'sweetalert2'

const PayPal = props => {
  const onPaypalSuccess = (details, data) => {
    console.log(
      'On Paypal Success in Checkout Form',
      details.payer.name.given_name,
      data
    )
    var description = `
      Program: ${props.program.title}
      Name: ${props.enrollInfo.name}
      Email: ${props.enrollInfo.email}
      Phone: ${props.enrollInfo.phone}
      Batch Number: ${props.batchNumber}
      Amount Paid:${props.fee}
    `
    console
      .log(`Description: ${description}`)
      .then(() => {
        Swal.fire(
          'Your Registration is Successful',
          `Thank you for your payment. We will send you payment receipt shortly to your registered email.`,
          'success'
        )
        props.onCloseModal()
        props.onClear()
      })
      .catch(() => {
        Swal.fire('Ooops..', `Failed to send email confirmation.`, 'error')
        props.onCloseModal()
        props.onClear()
      })
  }

  console.log(props.fee)

  return (
    <>
      <h5>Pay with Paypal</h5>
      <PayPalButton
        amount={props.fee}
        onSuccess={onPaypalSuccess}
        options={{
          clientId:
            'AVyZRQjsKyKKxSXb2crjonLYj5Y_jeQW9JiPV-Rl2lqWcgVVMJVE899QjAbgGme9s2_fN8ZKYjFwcTTH'
        }}
      />
    </>
  )
}

export default PayPal
