import React from 'react'
import Layout from 'components/Layout'
import Program from '../components/Program/Program'
import Nav from 'components/Nav'
import Footer from 'components/Footer'
import { graphql } from 'gatsby'
import Social from '../components/Socialicons'

export default ({ data }) => {
  let program = data.allContentfulProgram.nodes[0]
  return (
    <Layout
      title={`${program.title} - Coding Sastra`}
      desc={program.title}
      keywords={`${program.code}, ${program.title}`}
      path="/program/"
    >
      <Nav />
      <Social />
      <Program program={program} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query ProgramPageQuery($id: String) {
    allContentfulProgram(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        code
        acceptPayment
        fee
        desc {
          json
        }
        brochure {
          file {
            url
          }
        }
        batches {
          id
          batchNumber
          instructor
          startDate
          duration
        }
        picture {
          file {
            url
          }
        }
      }
    }
  }
`
