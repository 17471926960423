import React from 'react'
import styled, { css } from 'styled-components'
import { Flip } from 'react-reveal'
import codeImg from '../../assets/imgs/code.jpg'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import './program.css'

import {
  Container,
  Link as A,
  Section,
  Box,
  Text,
  theme
} from '@hackclub/design-system'
import { Headline, Lead } from 'components/Content'
import Link from 'components/Link'
import ProgramEnroll from './Enroll'
import ProgramBrochure from './Brochure'

const DESC = ({ children }) => <Text>{children}</Text>
const Bold = ({ children }) => (
  <h2>
    <strong>{children}</strong>
  </h2>
)

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <DESC>{children}</DESC>
  },
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>
  }
}

const Program = props => {
  A.link = A.withComponent(Link)
  const FeatureLink = styled(A.link).attrs({
    mt: 3,
    fontSize: 3,
    hoverline: true,
    chevronRight: true
  })`
    display: block;
  `
  const SectionLead = styled(Lead).attrs({
    fontSize: [3, 4],
    maxWidth: 88,
    mx: 0,
    pb: 3,
    mb: [4, 5]
  })``

  const shadows = css`
    h1,
    h2,
    h3,
    p,
    li,
    ${FeatureLink} {
      color: ${theme.colors.white};
      text-shadow: 0 1px 4px rgba(0, 0, 0, 1);
    }
  `
  const Slider = styled(Section).attrs({
    color: 'white'
  })`
    position: relative;
    border-radius: ${theme.radii[1]};
    background-color: ${theme.colors.primary};
    ${props =>
      props.inverted
        ? css`
            justify-content: flex-end !important;
            background-image: linear-gradient(
                'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)'
              ),
              url(${props.src});
          `
        : css`
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.2) 50%
              ),
              url(${props.src});
          `};
    background-position: center;
    background-size: cover;
    background-repeat: norepeat;
    ${shadows};
  `
  return (
    <>
      <div key={props.program.id}>
        <Slider
          style={{ paddingTop: 50 }}
          src={props.program.picture ? props.program.picture.file.url : codeImg}
          py={[3, 5, 6]}
          color="white"
          align={['left', 'center']}
        >
          <Container width={1} maxWidth={70} px={3} mt={[5, 6]} mb={[4, 5]}>
            <Flip top>
              <Headline maxWidth={58} mx="auto" fontSize={[6, 7, 8]} mb={2}>
                {props.program.title}
              </Headline>
            </Flip>
          </Container>
        </Slider>
        <Box bg="white" py={[5, 6]}>
          <Container color="black" px={3}>
            <Box px={[1, 8]}>
              <SectionLead>
                {documentToReactComponents(props.program.desc.json, options)}
              </SectionLead>
              <Text mb={5} fontSize={5}>
                <strong> Download Program Brochure</strong>
              </Text>
              <Box>
                <ProgramBrochure
                  url={
                    props.program.brochure
                      ? props.program.brochure.file.url
                      : ''
                  }
                />
              </Box>
              {
                props.program.batches && (
                  <Container mt={5}>
                    <Text mb={5} fontSize={5}>
                      <strong> Upcoming Batches</strong>
                    </Text>
                    <Table id="program">
                      <Thead>
                        <Tr>
                          <Th>
                            <strong>Batch Number</strong>
                          </Th>
                          <Th>
                            <strong>Faculty</strong>
                          </Th>
                          <Th>
                            <strong>Start Date</strong>
                          </Th>
                          <Th>
                            <strong>Duration</strong>
                          </Th>
                          <Th>
                            <strong>Enroll</strong>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {props.program.batches.map(batch => {
                          return (
                            <Tr key={batch.id}>
                              <Td>
                                <Text align="center" mt={3} mb={2}>
                                  {batch.batchNumber}
                                </Text>
                              </Td>
                              <Td>
                                <Text align="center">{batch.instructor}</Text>
                              </Td>
                              <Td>
                                <Text align="center">{batch.startDate}</Text>
                              </Td>
                              <Td>
                                <Text align="center">{batch.duration}</Text>
                              </Td>
                              <Td>
                                <ProgramEnroll
                                  program={props.program}
                                  batchNumber={batch.batchNumber}
                                  duration={batch.duration}
                                  amount={batch.amount}
                                />
                              </Td>
                            </Tr>
                          )
                        })}
                      </Tbody>
                    </Table>
                  </Container>
                )
              }

            </Box>
          </Container>
        </Box>
      </div>
    </>
  )
}

export default Program
